import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import empaqueCss from "./empaque.module.css";

const Empaque = () => {
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "empaque.png" }) {
      childImageSharp {
        fixed(width: 1200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
  `)

  return <Img fixed={data.file.childImageSharp.fixed} className={empaqueCss.Img}/>
}

export default Empaque
