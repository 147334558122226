import React from "react"
import disenoCss from "../../css/diseno.module.css"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
// la imagen
import EmpaqueIMG from "../../components/images/empaque/empaque"
import MenuDiseno from "../../components/menuDiseno/menuDiseno"
import LogoBlanco from "../../images/logo-blanco.svg"
import { Link } from "gatsby"

const Empaque = () => (
  <>
    <div className={disenoCss.background}>
      <div className={disenoCss.gradient}></div>
    </div>
    <EmpaqueIMG></EmpaqueIMG>
    <Link to="/" className={disenoCss.logoBlanco}>
      <img src={LogoBlanco} alt="Logotipo de engrane version blanca" />
    </Link>
    <MenuDiseno></MenuDiseno>
    <div className={disenoCss.container}>
      <Layout siteTitle="Diseño">
        <SEO title="Desarrollo Web" />
        <div className={disenoCss.contenido}>
          <h1 className={disenoCss.titulo}>
            be
            <br />
            packaging
          </h1>
          <p className={disenoCss.text}>
            ¿Te gustaría que el empaque de tu producto tenga una mejor imagen o
            se distinga ante la competencia? Nosotros te ayudamos a que tu
            empaque tenga una buena presentación y así mismo asegurarnos que
            este se mantenga limpio y fresco ya que es la presentación comercial
            de tu producto.
          </p>
        </div>
      </Layout>
    </div>
    <Link className={disenoCss.productos}>
      PRODUCTOS <span>&nbsp;&nbsp;&nbsp;→</span>
    </Link>
  </>
)

export default Empaque
